<template>
  <router-view />
</template>

<script setup lang="ts">
import { useQuasar } from 'quasar'
import * as Sentry from '@sentry/vue'

import { useRuntimeConfig } from 'src/services/RuntimeConfig'

import '@stockpulse-services/icons/font/stockpulse-icons.min.css'

const $q = useQuasar()
const runtimeConfig = useRuntimeConfig()

$q.iconMapFn = (iconName: string): { icon: string } | { cls: string; content?: string } | void => {
  // iconName is the content of QIcon "name" prop

  // Replace icons that are used by quasar components and cannot be modified by any property
  const replacements = new Map([
    ['arrow_upward', 'spi-angle-up'],
    ['arrow_drop_down', 'spi-angle-down'],
    // TODO
    ['keyboard_arrow_down', 'spi-angle-down'],
    ['chevron_left', 'spi-chevron-left'],
    ['chevron_right', 'spi-chevron-right'],
    ['error', 'spi-circle-exclamation']
  ])

  iconName = replacements.get(iconName) ?? iconName

  // Stockpulse icon library
  const spIconLibPrefixRegex = /^(sp.?)-/

  const match = iconName.match(spIconLibPrefixRegex)

  if (match) {
    const prefix = match[1]

    return {
      cls: `${prefix} ${iconName}`
    }
  }

  if (runtimeConfig.NODE_ENV === 'staging') {
    Sentry.captureMessage(`The icon ${iconName} is not from the Stockpulse Icon Library.`)
  }
  if (runtimeConfig.NODE_ENV === 'dev') {
    console.warn(`The icon ${iconName} is not from the Stockpulse Icon Library.`)
  }

  // when we don't return anything from our iconMapFn, the default Quasar icon mapping takes over
}
</script>
